import React, { useEffect, useContext, useState } from "react"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"
import axios from "axios"
import isURL from 'validator/lib/isURL';

import { IntlContext } from "../../intl"
import Layout from "../layout"
import SEO from "../seo"

export const query = graphql`
	query($id: String!) {
		prismicQrCodeGenerator(id: { eq: $id }) {
      uid
      lang
			data {
				title {
					text
				}
				description {
					text
				}
				header {
					text
				}
				url_help_text {
					text
				}
				generate_button_text {
					text
				}
				clear_button_text {
					text
				}
				download_button_text {
					text
				}
			}
      alternate_languages {
        lang
        url
        document {
          __typename
          ... on PrismicQrCodeGenerator {
            data {
              title {
                text
              }
            }
          }
        }
      }
		}
	}
`

const QRCodeGeneratorPage = ({ location, data }) => {

	const intl = useIntl()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicQrCodeGenerator.lang);
    setAlts(data.prismicQrCodeGenerator.alternate_languages || [])
	}, [changeLocale, setAlts, data])

  const [url, setUrl] = useState('')
  const [dataUrl, setDataUrl] = useState(null)

  const [generating, setGenerating] = useState(false)

	const [showInvalidUrl, setShowInvalidUrl] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [showError, setShowError] = useState(false)

  const submitForm = (e) => {
		e.preventDefault();

    const validUrl = isURL(url)

    setShowSuccess(false);
    setShowError(false);
    setShowInvalidUrl(!validUrl);

    if (validUrl) {
      setGenerating(true)
      axios.post(process.env.GATSBY_QR_URL, { url }).then(({ data }) => {
        setDataUrl(data)
        setShowSuccess(true)
      }).catch(error => {
        // TODO: log error
        console.log(error)
        setShowError(true)
      }).finally(() => {
        setGenerating(false)
      })
    }
  }

  const clear = () => {
    setShowSuccess(false);
    setShowError(false);
    setShowInvalidUrl(false);
    setUrl('');
    setDataUrl(null);
  }

  return (
    <Layout container={false}>
      <SEO 
        lang={data.prismicQrCodeGenerator.lang} 
        title={data.prismicQrCodeGenerator.data.title.text} 
        meta={[{
          "http-equiv": "content-language", 
          content: data.prismicQrCodeGenerator.lang
        }]} 
        links={data.prismicQrCodeGenerator.alternate_languages.concat([data.prismicQrCodeGenerator]).map(alt => ({
          rel: "alternate", 
          hreflang: alt.lang, 
          href: `https://www.customerradius.com${alt.url}`
        })).concat([{
          rel: "alternate", 
          hreflang: "x-default", 
          href: "https://www.customerradius.com/qr-code-generator"
        }])} />
      <div className="section has-background-info">
        <div className="container">
          <div className="column is-half is-offset-one-quarter">
            <h1 className="title has-text-centered has-text-white">
              {data.prismicQrCodeGenerator.data.header.text}
            </h1>
            <div className="card">
              <div className="card-content">
                <form className="" onSubmit={submitForm}>
                  <div className="field">
                    <label className="label" htmlFor="urlInput">URL</label>
                    <div className="control">
                      <input id="urlInput" className="input" type="text" maxLength="2048" placeholder="https://"
                        value={url}
                        onChange={e=>setUrl(e.target.value)} />
                      {showInvalidUrl && <p className="help is-danger">
                        {data.prismicQrCodeGenerator.data.url_help_text.text}
                      </p>}
                    </div>
                  </div>
                  {showError &&
                    <div className="notification is-danger">
                      <button className="delete" name="error" aria-label="close" onClick={()=>setShowError(false)}></button>
                      {intl.formatMessage({ id: "qr_code_generator.error" })}
                    </div>
                  }
                  <div className="field">
                    <div className="control buttons is-centered has-addons">
                      <button className={generating?"button is-rounded  is-medium is-loading":"button is-rounded  is-medium"} type="submit">
                        {data.prismicQrCodeGenerator.data.generate_button_text.text}
                      </button>
                      {(dataUrl && !generating) && 
                        <button className="button is-rounded is-light is-medium" type="button" onClick={clear}>
                          {data.prismicQrCodeGenerator.data.clear_button_text.text}
                        </button>
                      }
                    </div>
                  </div>
                </form>
                {showSuccess && 
                  <>
                    <div className="notification is-success mt-3">
                      <button className="delete" name="success" aria-label="close" onClick={()=>setShowSuccess(false)}></button>
                      <strong>{intl.formatMessage({ id: "qr_code_generator.success" })}</strong>
                    </div>
                  </>
                }
                {dataUrl && 
                  <div className="column is-half is-offset-one-quarter is-8-mobile is-offset-2-mobile">
                    <a  href={dataUrl} download="qr_code" target="_blank" rel="noreferrer">
                    <div className="has-text-centered">
                      <figure className="image">
                        <img src={dataUrl} alt="QR Code" />
                      </figure>
                      <span>{data.prismicQrCodeGenerator.data.download_button_text.text}</span>
                    </div>
                    </a>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
)}

export default QRCodeGeneratorPage
